import React from "react";
import gwGift from "../Images/GW-gift.png";
function GiftBox() {
  return (
    <div className="row">
    <div className="col-md-1">
      <img
        src={gwGift}
        alt="GW-gift.png"
      />
    </div>

    {/* <div className="col-md-11" style={{ fontFamily: "Arial" }}>
    Once you receive and action your email from{" "}
        <a href="mailto:notification@prepaiddigitalsolutions.com">
          {process.env.REACT_APP_PREP}
        </a>{" "}
        or <br />
        <a href="mailto:no-reply@hawkmarketplace.com">
          {process.env.REACT_APP_MARKET_PLACE}
        </a>
        , you can use the reward card virtually or request a card to be mailed to you.
    </div> */}
    <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive and action your email from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
          {process.env.REACT_APP_DESC_REWARD}
        </a>
        ,{" "}
        <a href={"mailto:" + process.env.REACT_APP_PREP}>
          {process.env.REACT_APP_PREP}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
          {process.env.REACT_APP_MARKET_PLACE}
        </a>{" "}
        , you can use the reward card virtually or request a card to be mailed
        to you.
      </div>
  </div>
  );
}
export default GiftBox;
